import React from "react";

import { useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { StyledLink } from "styled";

const ComingSoonCard = ({ episode }) => {
  const theme = useTheme();

  const {
    id,
    date,
    seasonNumber,
    number,
    pictureUrl,
    showName,
    showPictureUrl,
  } = episode;

  const episodePicUrl = pictureUrl ? pictureUrl : showPictureUrl;

  return (
    <Card
      sx={{
        maxWidth: theme.columnWidth,
        minWidth: theme.columnWidth,
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        sx={{
          height: theme.columnWidth * 1.5,
        }}
        image={episodePicUrl ? episodePicUrl : "/default-pic.png"}
      />
      <CardContent
        sx={{
          minHeight: 140,
          maxHeight: 140,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <StyledLink to={`/episode/${id}`}>
          <Typography gutterBottom variant="h5" noWrap>
            {`${showName} (S${seasonNumber}-E${number})`}
          </Typography>
        </StyledLink>
        <Typography gutterBottom variant="h5">
          <Moment format="DD MMM YYYY HH:mm A">{date}</Moment>
        </Typography>
      </CardContent>
    </Card>
  );
};

ComingSoonCard.propTypes = {
  episode: PropTypes.object,
};

export default ComingSoonCard;
