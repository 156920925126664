import { createSlice } from "@reduxjs/toolkit";
import { comingSoonModuleName } from "pages/ComingSoon/store/comingSoonConstants";
import { fetchComingSoonThunk } from "pages/ComingSoon/store/comingSoonThunks";

const initialState = { comingSoon: null };

const comingSoonSlice = createSlice({
  name: comingSoonModuleName,
  initialState,
  reducers: {
    clearComingSoonAction: (state) => {
      state.comingSoon = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComingSoonThunk.fulfilled, (state, { payload }) => {
      state.comingSoon = payload;
    });
  },
});

export const { clearComingSoonAction } = comingSoonSlice.actions;

export default comingSoonSlice.reducer;
