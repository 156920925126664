import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiLogin } from "session/api/sessionApi";
import { loginAction } from "session/store/sessionSlice";
import { VisibleStyledLink } from "styled";
import { handleApiError } from "utils";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.title = "Login | MySeries";
  });

  const dispatch = useDispatch();

  const login = async () => {
    try {
      await apiLogin(email, password);
      dispatch(loginAction());
      const beforeLoginState = localStorage.getItem("beforeLoginState");
      if (beforeLoginState) {
        localStorage.removeItem("beforeLoginState");
      }
      navigate(beforeLoginState ? beforeLoginState : "/", { replace: true });
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="i_email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="i_password"
            autoComplete="current-password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            onClick={login}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            id="b_login"
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={VisibleStyledLink} to="/privacy" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={VisibleStyledLink} to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
