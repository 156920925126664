import React, { useEffect } from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { CssBaseline } from "@mui/material";
import Header from "components/Header/Header";
import { useError } from "contexts/ErrorContext";
import { useLoading } from "contexts/LoadingContext";
import AboutPage from "pages/About/AboutPage";
import ComingSoonPage from "pages/ComingSoon/ComingSoonPage";
import EpisodePageWrapper from "pages/Episode/EpisodePageWrapper";
import LoginPage from "pages/Login/LoginPage";
import NewEpisodesPage from "pages/NewEpisodes/NewEpisodesPage";
import PrivacyPage from "pages/Privacy/PrivacyPage";
import SearchPage from "pages/Search/SearchPage";
import ShowPage from "pages/Show/ShowPage";
import ShowListPage from "pages/ShowList/ShowListPage";
import SignupPage from "pages/Signup/SignupPage";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { fetchSessionThunk } from "session/store/sessionThunks";
import { setupAxios } from "setupAxios";

const Layout = () => (
  <>
    <Header />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <ShowListPage /> },
      { path: "/show/:id", element: <ShowPage /> },
      { path: "/episode/:id", element: <EpisodePageWrapper /> },
      { path: "/search", element: <SearchPage /> },
      { path: "/new-episodes", element: <NewEpisodesPage /> },
      { path: "/coming-soon", element: <ComingSoonPage /> },
      { path: "/about", element: <AboutPage /> },
      { path: "/privacy", element: <PrivacyPage /> },
      { path: "*", element: <h1>Not Found</h1> },
      { path: "/login", element: <LoginPage /> },
      { path: "/signup", element: <SignupPage /> },
    ],
  },
]);

const App = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { setError, setErrorOpen } = useError();
  const { initialized, loggedIn } = useSelector((state) => state.session);

  useEffect(() => {
    setupAxios(setLoading, setError, setErrorOpen);
  }, [setLoading, setError, setErrorOpen]);

  useEffect(() => {
    dispatch(fetchSessionThunk());
  }, [dispatch, loggedIn]);

  if (!initialized) {
    return null;
  }

  return (
    <div>
      <CssBaseline />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
