import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchComingSoon } from "pages/ComingSoon/api/comingSoon";
import { comingSoonModuleName } from "pages/ComingSoon/store/comingSoonConstants";
import { handleApiError } from "utils";

export const fetchComingSoonThunk = createAsyncThunk(
  `${comingSoonModuleName}/fetchComingSoon`,
  async () => {
    try {
      return await apiFetchComingSoon();
    } catch (error) {
      handleApiError(error);
    }
  },
);
