import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import ComingSoonCard from "pages/ComingSoon/components/ComingSoonCard";
import { clearComingSoonAction } from "pages/ComingSoon/store/comingSoonSlice";
import { fetchComingSoonThunk } from "pages/ComingSoon/store/comingSoonThunks";
import { useDispatch, useSelector } from "react-redux";
import { AutoFitGrid, PageGrid, TitleTypography } from "styled";

const ComingSoonPage = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.session);
  const { comingSoon } = useSelector((state) => state.comingSoon);

  useEffect(() => {
    document.title = "Coming soon | MySeries";
    dispatch(fetchComingSoonThunk());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearComingSoonAction());
    };
  }, []);

  if (!loggedIn) {
    return (
      <PageGrid container>
        <Grid item>
          <TitleTypography variant="h4">Login Required ...</TitleTypography>
        </Grid>
      </PageGrid>
    );
  }

  if (!comingSoon) {
    return null;
  }

  if (comingSoon.length === 0) {
    return (
      <PageGrid container>
        <Grid item>
          <TitleTypography variant="h4">Nothing coming soon</TitleTypography>
        </Grid>
      </PageGrid>
    );
  }

  return (
    <PageGrid container>
      <Grid item>
        <TitleTypography variant="h4">Coming Soon</TitleTypography>
      </Grid>
      <AutoFitGrid container>
        {comingSoon.map((episode) => (
          <Grid key={episode.id} item>
            <ComingSoonCard episode={episode} />
          </Grid>
        ))}
      </AutoFitGrid>
    </PageGrid>
  );
};

export default ComingSoonPage;
