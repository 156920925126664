import * as React from "react";
import { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputBase } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiLogout } from "session/api/sessionApi";
import { logoutAction } from "session/store/sessionSlice";
import { StyledLink } from "styled";
import { handleApiError } from "utils";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchStr, setSearchStr] = useState("");

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const { loggedIn, userInfo } = useSelector((state) => state.session);

  useEffect(() => {
    if (!location.pathname.startsWith("/search")) {
      if (searchStr !== "") {
        setSearchStr("");
      }
      if (mobileSearchOpen) {
        setMobileSearchOpen(false);
      }
    }
  }, [location]);

  const handleSearchInput = (event) => {
    const value = event.target.value;
    setSearchStr(value);
    if (event.key === "Enter" && value) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      navigate(`/search?searchStr=${value}`);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileClick = () => {
    handleCloseUserMenu();
  };

  const handleLogin = () => {
    const url = new URL(window.location.href);
    localStorage.setItem(
      "beforeLoginState",
      `${url.pathname + url.search + url.hash}`,
    );
    navigate("/login");
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    try {
      await apiLogout();
      dispatch(logoutAction());
      const path = new URL(window.location.href).pathname;
      if (path.includes("new-episodes") || path.includes("coming-soon")) {
        navigate("/");
      } else {
        navigate(path, { replace: true });
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <StyledLink to={"/"}>
            <LiveTvIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          </StyledLink>
          <StyledLink to={"/"}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              MYSERIES
            </Typography>
          </StyledLink>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <StyledLink to={"/?page=1&category=ALL"}>
                <MenuItem key={"shows"} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Shows</Typography>
                </MenuItem>
              </StyledLink>
              {loggedIn ? (
                <StyledLink to="/new-episodes">
                  <MenuItem key={"myseries"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">New Episodes</Typography>
                  </MenuItem>
                </StyledLink>
              ) : null}
              {loggedIn ? (
                <StyledLink to="/coming-soon">
                  <MenuItem key={"myseries"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Coming Soon</Typography>
                  </MenuItem>
                </StyledLink>
              ) : null}
              <StyledLink to="/about">
                <MenuItem key={"about"} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">About Myseries</Typography>
                </MenuItem>
              </StyledLink>
              <StyledLink to="/privacy">
                <MenuItem key={"privacy"} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Privacy & GDPR</Typography>
                </MenuItem>
              </StyledLink>
            </Menu>
          </Box>
          <StyledLink to={"/"}>
            <LiveTvIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          </StyledLink>
          <Grid
            item
            to={"/"}
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            {mobileSearchOpen ? (
              <InputBase
                id="searchInputMobile"
                inputProps={{ "aria-label": "search" }}
                placeholder="Search…"
                value={searchStr}
                inputRef={inputRef}
                onChange={handleSearchInput}
                onKeyDown={handleSearchInput}
                autoFocus
                fullWidth
                sx={{
                  maxWidth: "180px",
                  color: "inherit",
                  "& .MuiInputBase-input": {},
                }}
                endAdornment={
                  <IconButton
                    onClick={() => setMobileSearchOpen(false)}
                    color="inherit"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            ) : (
              <>
                <StyledLink to={"/"}>
                  {" "}
                  <Typography
                    variant="h5"
                    noWrap
                    sx={{
                      fontFamily: "monospace",
                      fontWeight: 700,
                      letterSpacing: ".3rem",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    MYSERIES
                  </Typography>
                </StyledLink>

                <IconButton
                  onClick={() => setMobileSearchOpen(true)}
                  color="inherit"
                  sx={{ p: 0, mr: 1 }}
                >
                  <SearchIcon />
                </IconButton>
              </>
            )}
          </Grid>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <StyledLink to={"/?page=1&category=ALL"}>
              <Button
                key={"shows-page"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Shows
              </Button>
            </StyledLink>
            {loggedIn ? (
              <StyledLink to={"/new-episodes"}>
                <Button
                  key={"new-episodes-page"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  New Episodes
                </Button>
              </StyledLink>
            ) : null}
            {loggedIn ? (
              <StyledLink to={"/coming-soon"}>
                <Button
                  key={"coming-soon-page"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Coming Soon
                </Button>
              </StyledLink>
            ) : null}
            <StyledLink to={"/about"}>
              <Button
                key={"about-page"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                About Myseries
              </Button>
            </StyledLink>
            <StyledLink to={"/privacy"}>
              <Button
                key={"privacy-page"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Privacy & GDPR
              </Button>
            </StyledLink>
            <InputBase
              id="searchInputDesctop"
              inputProps={{ "aria-label": "search" }}
              placeholder="Search…"
              value={searchStr}
              inputRef={inputRef}
              onChange={handleSearchInput}
              onKeyDown={handleSearchInput}
              sx={{
                my: 2,
                width: "150px",
                background: "rgba(255, 255, 255, 0.15)",
                borderRadius: "4px",
                marginLeft: "10px",
                padding: "0px 10px",
              }}
            />
          </Box>

          {loggedIn ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userInfo.email}>{userInfo.email.at(0)}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={"profile"} onClick={handleProfileClick}>
                  <Typography textAlign="center">{userInfo.email}</Typography>
                </MenuItem>
                <MenuItem key={"logout"} onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Button color="inherit" onClick={handleLogin}>
              LOGIN
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
